import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Card } from '@mui/material';

export const ConnectSpotifyCTACard = ({ goBack, isBack }: { goBack?: () => void; isBack?: boolean }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Card className="max-w450 ml-auto mr-auto text-left">
      <h4>{t('COMMON.CONNECT-YOUR-SPOTIFY-ACCOUNT')}</h4>
      <p className="text-faded mt10">{t('COMMON.CONNECT-YOUR-SPOTIFY-ACCOUNT-DESCRIPTION')}</p>
      <Button
        className="w100p m0 btn-white mt20"
        onClick={() => navigate('/profile', { state: { redirect: window.location.pathname + window.location.search } })}
      >
        {t('COMMON.CONNECT-ACCOUNT')}
      </Button>
      {isBack && (
        <Button className="w100p m0 mt10 btn-black" onClick={goBack}>
          Go back
        </Button>
      )}
    </Card>
  );
};
