import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Icon, Modal } from '@mui/material';

import { Images } from '@/constants/Images';
import useSubscription from '@/hooks/account/useSubscription';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { PromoteFlowQueryParams } from '@/models/Enums';

import AIGeneratorModal from '../promote-modals/AIGeneratorModal';
import Card from '../utility/microcomponents/Card';

export const ArtistBioCard = ({ isBio }: { isBio?: boolean }) => {
  const [flowOpen, setFlowOpen] = useState<boolean>(false);
  const { breakpointHit } = useBreakpoints();
  const { isSubscribed } = useSubscription();
  const [params, setSearchParams] = useSearchParams();

  return (
    <>
      <Modal
        open={flowOpen}
        onClose={() => {
          setFlowOpen(false);
        }}
      >
        <>
          <AIGeneratorModal
            type={isBio ? 'artist-bio' : 'press-release'}
            closeModalOutput={() => {
              setFlowOpen(false);
            }}
          />
        </>
      </Modal>
      <Card
        inner
        onClick={() => {
          if (!isSubscribed) {
            params.set(PromoteFlowQueryParams.SUBSCRIPTION, 'true');
            return setSearchParams(params);
          }
          setFlowOpen(true);
        }}
        className={`p0 flex-grow ${breakpointHit ? 'min-h400 min-w300' : 'min-h400 min-w400 max-h400'} pos-rel flex-basis placeholder-card-blue overflow-hidden cursor-pointer`}
      >
        <div className={`d-flex pos-rel jc-center gap20 overflow-hidden flex-wrap`}>
          <img
            src={isBio ? Images.placeholders.artistBio : Images.placeholders.pressRelease}
            alt="bio-image"
            width={isBio ? '95%' : '70%'}
            className={` ${isBio ? 'mt30' : 'mt30'} max-w500`}
          />
          {!isSubscribed && (
            <img className="pos-abs r10 t10" src="/images/logos/subscription-pro-lock-logo-light.svg" alt="lock" />
          )}
        </div>

        <div className="d-flex card-inner-inner p16 pos-abs b0 w100p bg-blur br-b-12 br-t-0 text-left gap10">
          <div className={`mt-auto mb-auto flex-grow`}>
            <h4>{isBio ? 'Create an Artist Bio' : 'Create a Press Release'}</h4>

            <p className="text-reduced-opacity mt4">
              {isBio
                ? 'Create an artist bio highlighting you musical journey, influences, and achievements.'
                : 'Create a compelling press release designed to help you articulate your story.'}
            </p>

            {breakpointHit && (
              <Button
                className="btn-white w100p m0 mt20"
                onClick={() => {
                  if (!isSubscribed) {
                    params.set(PromoteFlowQueryParams.SUBSCRIPTION, 'true');
                    return setSearchParams(params);
                  }
                  setFlowOpen(true);
                }}
              >
                <Icon>add</Icon>
                <span className="pl8">{isBio ? 'Create artist bio' : 'Create press release'}</span>
              </Button>
            )}
          </div>
          {!breakpointHit && (
            <>
              <Button
                className="icon-btn ml-auto btn-grey br50p mt-auto mb-auto"
                onClick={() => {
                  if (!isSubscribed) {
                    params.set(PromoteFlowQueryParams.SUBSCRIPTION, 'true');
                    return setSearchParams(params);
                  }
                  setFlowOpen(true);
                }}
              >
                <Icon>add</Icon>
              </Button>
            </>
          )}
        </div>
      </Card>
    </>
  );
};
